import api from "@/api";

export default {
  config(data) {
    return api.execute({ ...api.requestCreator("GET", `config`), data });
  },
  simulate(data) {
    return api.execute({ ...api.requestCreator("POST", `simulate`), data });
  },
  potentials(data) {
    return api.execute({ ...api.requestCreator("POST", `potentials`), data });
  },
  dimensioning(data) {
    return api.execute({ ...api.requestCreator("POST", `dimensioning`), data });
  },
  users(data) {
    return api.execute({ ...api.requestCreator("GET", 'users'), data });
  }
};
