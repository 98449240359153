<template>
  <div class="max-w-7xl">
    <ParameterForm
        v-on:simulate="updateSimulation"
        class="float-left w-full md:w-1/3 p-5"
    />
    <TextAreaDebugger
        v-on:change="parseDebugString"
        v-if="false"
        class="float-left md-full"
    />
    <t-alert
        :variant="error.severity === 'Error' ? 'danger' : 'warning'"
        v-if="error"
        show
        class="float-left mt-10 w-full md:w-2/3"
        @hidden="removeError"
    >
      {{ error.message }}
    </t-alert>
    <div
        class="float-left w-full md:w-2/3"
        v-if="!loading && !(error && error.severity === 'Error')"
    >
      <div class="float-left">
        <TabArea
            value="Hilfe"
            :activeTab="getActiveTab"
            v-on:click.native="setActiveTab('Hilfe')"
        />
        <TabArea
            value="Übersicht"
            :activeTab="getActiveTab"
            v-on:click.native="setActiveTab('Übersicht')"
        />
        <TabArea
            v-if="!isCustomer"
            value="Zeichnung"
            :activeTab="getActiveTab"
            v-on:click.native="setActiveTab('Zeichnung')"
        />
        <TabArea
            v-if="!isCustomer"
            value="Berechnung"
            :activeTab="getActiveTab"
            v-on:click.native="setActiveTab('Berechnung')"
        />
      </div>
      <HilfeSystem
          v-if="getActiveTab === 'Hilfe'"
          class="float-left w-full"
      />
      <ResultOverview
          v-if="getActiveTab === 'Übersicht'"
          class="float-left w-full"
      />
      <SvgView
          class="float-left w-screen md:w-full overflow-hidden md:overflow-visible"
          v-if="getActiveTab === 'Zeichnung'"
      />
      <DimensioningTable
          v-if="getActiveTab === 'Berechnung'"
          class="float-left w-full"
      />
      <PriceTable v-if="getActiveTab === 'Preis'" class="float-left w-full"/>
    </div>
    <div v-if="loading">
      <div class="lds-ring mt-32 md:mt-10">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import TextAreaDebugger from "@/components/TextAreaDebugger.vue";
import ParameterForm from "@/components/ParameterForm.vue";
import SvgView from "@/components/SvgView.vue";
import ResultOverview from "@/components/ResultOverview.vue";
import DimensioningTable from "@/components/DimensioningTable.vue";
import PriceTable from "@/components/PriceTable.vue";
import TabArea from "@/components/TabArea.vue";
import ConfiguratorService from "@/services/ConfiguratorService.js";
import HilfeSystem from "@/components/HilfeSystem.vue";

export default {
  name: "HelloWorld",
  components: {
    HilfeSystem,
    TextAreaDebugger,
    ParameterForm,
    SvgView,
    TabArea,
    ResultOverview,
    DimensioningTable,
    PriceTable,
  },
  data() {
    return {
    };
  },
  /*
   * Diese Funktion wird aufgerufen, wenn das System initialisiert wurde.
   * Sie dient dazu, die benötigten Werte initial zu setzen
   */
  async mounted() {
    // Der aktive Tab soll "Hilfe" sein.
    this.setActiveTab('Hilfe');

    /*
     * Sollte es noch keine Konfiguration geben, dann diese setzen.
     */
    if (!this.$store.config) {
      const config = await ConfiguratorService.config();
      this.$store.dispatch("setConfig", config);
    }
    /*
     * Sollte es noch keinen User geben, dann diesen setzen.
     */
    if (!this.$store.users) {
      const users = await ConfiguratorService.users();
      this.$store.dispatch("setUsers", users);
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    config() {
      return this.$store.state.config;
    },
    error() {
      return this.$store.state.error;
    },
    showPotentials() {
      return this.$store.state.showPotentials;
    },
    /*
     * Holt den aktiven Tab.
     * Da wir von mehreren Komponenten aus darauf zugreifen (Formular, Haupt-Seite, Hilfe-System)
     * speichern wir diesen Wert nicht in einem lokalen Objekt, sondern im globalen Store.
     */
    getActiveTab() {
      return this.$store.state.activeTab ?? 'Hilfe';
    },
    /*
     * Liefert true zurück, WENN es sich um einen Kunden liefert.
     */
    isCustomer() {
      /*
       * Wenn es sich um "localhost" handelt, sind wir im Programmieren. Dann bestimmt der Parameter
       * "Kundennummer" ob es ein Kunde ist, oder eben nicht.
       * (hostname liefert nur den Namen. host würde den Port mit liefern)
       */
      if (document.location.hostname.toLowerCase() === 'localhost') {
        return this.$route.query.sw6_kdnr !== undefined && this.$route.query.sw6_kdnr.length > 0;
      }
      /*
       * Wenn es sich um die im Internet verfügbare KUNDEN-URL handelt, ist es der Kunden-Modus
       */
      if (document.location.hostname.toLowerCase() === 'konfigurator.kroener-dichtungstechnik.de') {
        return true;
      }
      /*
       * Wenn es sich um die im Internet verfügbare INTERNE-URL handelt, ist es der NICHT Kunden-Modus
       */
      if (document.location.hostname.toLowerCase() === 'konfigurator-intern.kroener-dichtungstechnik.de') {
        return false;
      }

      /*
       * Ansonsten sicherheitshalber die eingeschränkte Kunden-GUI anzeigen
       */
      return false;
    },
  },
  methods: {
    /*
     * Setzt den aktiven Tab.
     * Da wir von mehreren Komponenten aus darauf zugreifen (Formular, Haupt-Seite, Hilfe-System)
     * speichern wir diesen Wert nicht in einem lokalen Objekt, sondern im globalen Store.
     */
    setActiveTab(tab) {
      this.$store.state.activeTab = tab;
    },

    parseDebugString: function (string) {
      try {
        string = JSON.parse(string);
      } catch (error) {
        console.log("Not a valid JSON string to debug");
      }
      this.$store.dispatch("setSimulationResult", {
        circles: string.circles,
        outerCircle: string.outerCircle,
      });
    },
    updateSimulation: async function (data) {
      // Die Übersicht aktivieren, da wir ja sonst das Ergebnis nicht angezeigt bekommen,
      // sondern stattdessen z.B. die Hilfe-Seite.
      this.setActiveTab('Übersicht');

      this.$store.dispatch("setLoading", true);
      this.$store.dispatch("removeError");
      var result = await ConfiguratorService.simulate(data);
      if (!result) {
        this.$store.dispatch(
            "setError",
            "An error occured while performing the request"
        );
        this.$store.dispatch("setLoading", false);
        return false;
      } else if (result.error) {
        this.$store.dispatch("setError", result.error);
        this.$store.dispatch("setLoading", false);
        return false;
      } else {
        this.$store.dispatch("setSimulationResult", result);
        // if (result.runs === this.config.config.MAXRUNS) {
        //   this.$store.dispatch(
        //     "setWarning",
        //     "Simulation abgebrochen nach " +
        //       this.config.config.MAXRUNS +
        //       " Ticks. Rest-Aktivität: " +
        //       result.activity.toFixed(3) +
        //       ". Ziel-Aktivität: " +
        //       (
        //         result.outerCircle.r *
        //         result.circles.length *
        //         this.config.config.MINACTIVITY
        //       ).toFixed(3)
        //   );
        // }
      }
      if (this.showPotentials) {
        var potentials = await ConfiguratorService.potentials({
          circles: this.getCirclesWithoutTicks(result.circles),
          radius: result.outerCircle.r,
        });
        this.$store.dispatch("setPotentials", potentials);
      }
      this.$store.dispatch("setLoading", false);
    },
    removeError() {
      this.$store.dispatch("removeError");
    },
    getCirclesWithoutTicks(circles) {
      var circlesWithoutTicks = [];
      circles.forEach((circle) => {
        var circleWithoutTicks = _.cloneDeep(circle);
        delete circleWithoutTicks.ticks;
        circlesWithoutTicks.push(circleWithoutTicks);
      });
      return circlesWithoutTicks;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
