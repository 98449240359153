<template>
  <div id="app">
    <HeaderNavigation class="w-full"/>
    <!--
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    -->
    <router-view class="container mx-auto muv-view"/>
    <!--    <div class="mt-4 text-red-500" v-if="!gotParam">Fehler: Es wurde keine Kundennummer übergeben!</div>-->
    <div class="w-full flex p-4 h-16" style="background-color:#195ba3">
    </div>

    <div class="w-full p-4 flex align-middle justify-center">
      <a href="https://www.kroener-dichtungstechnik.de/Informationen/Impressum/"
         target="_blank"
         class="mr-3"
      >
        Impressum
      </a>

      <a href="https://www.kroener-dichtungstechnik.de/Informationen/Datenschutz/"
         target="_blank"
      >
        Datenschutz
      </a>

    </div>

  </div>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";

export default {
  components: {
    HeaderNavigation,
  },
  data() {
    return {
      gotParam: (this.$route.query?.sw6_kdnr)
    }
  }

};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
