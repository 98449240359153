<template>
  <div class="mb-5">
    <p class=" mt-5 my-3 font-bold">Positionen der Schrauben und Rohre</p>
    <table class="min-w-full divide-y divide-gray-200 text-xs text-left">
      <thead class="bg-gray-50">
        <th
          scope="col"
          class="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
        >
          Bauteil
        </th>
        <th
          scope="col"
          class="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
        >
          Anzahl
        </th>
        <th
          scope="col"
          class="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
        >
          Preis / Einheit
        </th>
        <th
          scope="col"
          class="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
        >
          Preis
        </th>
      </thead>
      <tbody
        class="bg-white divide-y divide-gray-200 text-left text-sm text-gray-900"
      >
        <tr v-for="price in prices" :key="price.layer">
          <td class="p-2">
            {{ price.layer }}
          </td>
          <td class="p-2">
            {{ price.number }}
          </td>
          <td class="p-2">{{ parseFloat(price.price).toFixed(4) }}</td>
          <td class="p-2">
            {{ (price.number * parseFloat(price.price)).toFixed(4) }}
          </td>
        </tr>
        <tr>
          <td><b>Gesamtpreis</b></td>
          <td></td>
          <td></td>
          <td>
            <b>{{ sum }}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "PriceTable",
  data() {
    return {};
  },
  computed: {
    prices() {
      return this.$store.state.prices;
    },
    sum() {
      var sum = 0;
      this.prices.forEach((price) => {
        sum += price.number * parseFloat(price.price);
      });
      return sum;
    },
  },
};
</script>
