<template>
  <div class="text-left">
    <form v-on:submit.prevent="">
      <div class="mb-5">
        <p class="text-lg font-bold">Allgemein</p>
        <div class="w-1/1" v-if="!isCustomer">
          <div class="float-left text-sm text-gray-500 pb-1">
            Nutzer
          </div>
          <div>
            <t-select
                v-model="username"
                placeholder="Bitte auswählen"
                name="username"
                :options="this.users"
                @focus="zeigeHilfe('')"
            >
            </t-select>
          </div>
        </div>
        <div>
          <div class="w-1/1 mt-3">
            <div class="float-left text-sm text-gray-500 pb-1">
              Produkt
            </div>
            <div>
              <t-select
                  v-model="component"
                  placeholder="Bitte auswählen"
                  name="component"
                  :options="{'Dichtung':'Pressringdichtung', 'Einsatz': 'Dichtungseinsatz Gebäudeeinführung'}"
                  @focus="zeigeHilfe('')"
              />
            </div>
          </div>
        </div>
        <div class="w-1/1 mt-3">
          <div class="float-left text-sm text-gray-500 pb-1">
            Benötigte Anzahl
          </div>
          <div>
            <t-input
                v-model="orderAmount"
                name="order-amount"
                type="number"
                min="1"
                @focus="zeigeHilfe('')"
            />
          </div>
        </div>
      </div>
      <div v-if="!!component">
        <div>
          <p class="text-lg font-bold">Futterrohr und Elastomer</p>

          <div class="flex flex-row" v-if="component === 'Dichtung'">
            <div class="w-1/2">
              <div class="float-left text-sm text-gray-500 pb-1">
                Rohrinnendurchmesser [mm]
              </div>
              <div class="pr-5">
                <t-input
                    v-model="diameter"
                    name="diameter"
                    type="number"
                    min="30"
                    max="800"
                    @focus="zeigeHilfe('diameter')"
                />
              </div>
            </div>
            <div class="w-1/2">
              <div class="text-right text-sm text-gray-500 pb-1">
                Zulässiger max. Druck [bar]
              </div>
              <div class="pl-5">
                <t-input
                    v-model="maxPressure"
                    v-if="!isCustomer || pressureInput"
                    name="maxPressure"
                    type="number"
                    step="0.1"
                    min="0"
                    @focus="zeigeHilfe('maxPressure')"
                />
                <t-select
                    v-model="maxPressure"
                    v-else
                    name="maxPressure"
                    @change="switchPressure($event)"
                    variant="demo"
                    :options="[...this.maxPressures, 'mehr']"
                    @focus="zeigeHilfe('maxPressure')"
                />
              </div>
              <div class="text-sm pt-4 text-right relative">Druck in
                {{ Math.round(maxPressure * 10.1974) }} m Wassertiefe
                <div class="absolute top-0 -right-3 cursor-pointer tooltip">
                  <font-awesome-icon icon="fa-solid fa-circle-question"/>
                  <div class="tooltip-text">Weitere informationen:<br/>
                    <a target="fhrk"
                       href="https://fhrk.de/wp-content/uploads/2023/03/Planungshilfe_05_2022_v3_220726_WEB-1.pdf">
                      https://fhrk.de/wp-content/uploads/2023/03/Planungshilfe_05_2022_v3_220726_WEB-1.pdf
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row mt-3">
            <!-- Der Kunde bekommt die Auswahl des Elastomers angezeigt -->
            <div class="w-full" v-if="isCustomer">
              <div class="text-sm text-gray-500 pb-1 text-left">
                Material des Elastomers
              </div>
              <div class="">
                <t-select
                    placeholder="Bitte auswählen"
                    :options="elastomerWerkstoffAuswahl"
                    v-model="elastomerType"
                    variant="demo"
                    @focus="zeigeHilfe('')"
                />
              </div>
            </div>
            <!-- Der Mitarbeiter bekommt die Auswahl des Elastomers und die Höhe angezeigt -->
            <div class="w-1/2" v-if="!isCustomer">
              <div class="text-sm text-gray-500 pb-1 text-left">
                Material des Elastomers
              </div>
              <div class="pr-5">
                <t-select
                    placeholder="Bitte auswählen"
                    :options="this.config?.components?.Elastomer.Auswahl.Werkstoff"
                    v-model="elastomerType"
                    variant="demo"
                    @focus="zeigeHilfe('')"
                />
              </div>
            </div>
            <div class="w-1/2" v-if="!isCustomer">
              <div class="text-right text-sm text-gray-500 pb-1">
                Höhe des Elastomers [mm]
              </div>
              <div class="pl-5">
                <t-select
                    placeholder="Bitte auswählen"
                    :options="this.config?.components?.Elastomer.Auswahl.Dicke"
                    v-model="heightElastomer"
                    variant="demo"
                    @focus="zeigeHilfe('')"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-row mt-3" v-if="component === 'Dichtung'">
            <div class="w-1/2">
              <div class="text-sm text-gray-500 pb-1 text-left">
                Flansch
              </div>
              <div class="pr-5">
                <t-select
                    name="flange"
                    placeholder="Bitte auswählen"
                    :options="['ja', 'nein']"
                    v-model="flange"
                    variant="demo"
                    @focus="zeigeHilfe('')"
                />
              </div>
            </div>
            <div class="w-1/2" v-if="this.flange === 'ja'">
              <div class="text-right text-sm text-gray-500 pb-1">
                Aussendm. Flansch [mm]
              </div>
              <div class="pl-5">
                <t-input
                    v-model="flangeWidth"
                    name="flangeWidth"
                    type="number"
                    :min="Number(diameter) + 60"
                    @focus="zeigeHilfe('flangeWidth')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5" v-if="component === 'Dichtung' && !isCustomer">
          <p class="text-lg font-bold">Schrauben und Muttern</p>
          <div class="flex flex-row">
            <div class="w-1/2">
              <div class="text-left text-sm text-gray-500 pb-1">
                Schraubentyp
              </div>
              <div class="pr-5">
                <t-select
                    placeholder="Bitte auswählen"
                    :options="this.config?.components?.Schraube.Auswahl"
                    v-model="screwType"
                    variant="demo"
                    @focus="zeigeHilfe('')"
                />
              </div>
            </div>
            <div class="w-1/2">
              <div class="text-right text-sm text-gray-500 pb-1">
                Schraubengröße
              </div>
              <div class="pl-5">
                <t-select
                    placeholder="Bitte auswählen"
                    :options="screwOptions"
                    v-model="screwWidth"
                    variant="demo"
                    @focus="zeigeHilfe('')"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-row mt-3">
            <div class="w-1/2">
              <div class="float-left text-sm text-gray-500 pb-1">
                Mutterntyp
              </div>
              <div class="pr-5">
                <t-select
                    placeholder="Bitte auswählen"
                    :options="this.config?.components?.Mutter.Auswahl"
                    v-model="nuts"
                    variant="demo"
                    @focus="zeigeHilfe('')"
                />
              </div>
            </div>
            <div class="w-1/2">
              <div class="text-right text-sm text-gray-500 pb-1">
                Anzahl Schrauben erzwingen
              </div>
              <div>
                <div class="pl-5">
                  <t-input
                      v-model="forceNumberScrews"
                      name="forceNumberScrews"
                      type="number"
                      min="1"
                      @focus="zeigeHilfe('')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5" v-if="component === 'Einsatz'">
          <p class="text-lg font-bold">Einsatz</p>
          <div class="flex flex-row mt-3">
            <div>
              <div class="text-left text-sm text-gray-500 pb-1">
                Größe des Einsatzes [mm]
              </div>
              <div>
                <t-select
                    placeholder="Bitte auswählen"
                    :options="Object.keys(this.config?.components?.Einsatz.Auswahl)"
                    v-model="insertSize"
                    name="insert"
                    variant="demo"
                    @focus="zeigeHilfe('')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <p class="text-lg font-bold">Leitungen</p>
          <p class="text-sm text-gray-500">Aussendurchmesser [mm]</p>
          <div
              v-for="(pipe, index) in pipes"
              :key="pipe.index"
              class="float-left w-full"
          >
            <div class="float-left text-sm text-gray-500 pt-3 w-full">
              d{{ pipe.index + 1 }}
            </div>
            <div class="w-3/4 float-left">
              <t-input
                  class="pipe-input"
                  v-model="pipe.diameter"
                  name="pipe"
                  type="number"
                  step=".01"
                  min="0"
                  :max="diameter"
                  @focus="zeigeHilfe('pipe-input')"
              />
            </div>
            <div class="w-1/4 float-left text-center">
              <p
                  @click="removePipe(index)"
                  class="text-xl font-bold pt-2 pl-3 cursor-pointer"
              >
                <!-- Papierkorb https://github.com/tailwindlabs/heroicons/blob/master/LICENSE -->
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                </svg>
              </p>
            </div>
          </div>

          <div>
            <t-button-secondary
                type="button"
                class="float-left w-full mt-5 mb-5 transition-colors duration-150 shadow-md"
                @click="addPipe"
            >Leitung hinzufügen
            </t-button-secondary
            >
          </div>
          <t-button
              class="float-left w-full mt-2 h-20  transition-colors duration-150 shadow-md"
              :disabled="loading"
              type="button"
              @click="simulate"
          >{{ loading ? "Berechnung läuft ..." : "Berechnung" }}
          </t-button
          >
          <!--          <t-button-->
          <!--              class="float-left w-full mt-2 h-20  transition-colors duration-150 shadow-md"-->
          <!--              bgcolor="orange"-->
          <!--              type="button"-->
          <!--              v-else-->
          <!--              @click="contactMail('pressure')"-->
          <!--          >Anfragen</t-button-->
          <!--          >-->
          <div class="flex w-full flex-row pt-5 "
               :class="{ 'justify-between': component === 'Dichtung', 'justify-end': component !== 'Dichtung' }">
            <t-button v-if="component === 'Dichtung'" type="button"
                      class="transition-colors duration-150 shadow-md" :disabled="loading"
                      @click="splitSealClick">Geteilte Dichtung
            </t-button>
            <t-button type="button" class="transition-colors duration-150 shadow-md bg-red" bgcolor="red"
                      style="background: red;" @click="deleteInputs">Löschen
            </t-button>
          </div>
        </div>
      </div>
    </form>
    <div class="float-left w-full text-center my-3 text-xs text-gray-300">
      Build: {{ buildDate }}
    </div>
  </div>
</template>

<script>
/*
 * Leider ist an dieser Stelle this.$route.query nicht verfügbar.
 * Also DIREKT auf die Parameter zugreifen...
 */
let uri = window.location.search.substring(1);
let params = new URLSearchParams(uri);

/*
 * Das gewünschte Elastomer-Material
 */
let elastomerMaterial = params.get("sw6_elastomer") ?? "EPDM";

/*
 * Das übergebene Material MUSS sich innerhalb der verwendeten Materialien befinden.
 * Leider habe ich jedoch an dieser Stelle das Problem, dass ich noch nicht auf die
 * Config zugreifen kann (wird vom Backoffice geliefert) und deshalb diese Überprüfung
 * an dieser Stelle noch gar nicht tun kann.
 * Das ist aber nicht wirklich ein Problem, da die GUI ja eine Dropdown-Liste besitzt
 * und dieser Wert nur die ERSTE Auswahl darstellt, nicht den später tatsächlich auagewählten
 * Wert. Sollte die Vorgabe also falsch sein, so wird innerhalb der Dropdown einfach nichts
 * ausgewählt. Dann wird auch nichts berechnet. Das ist nicht weiter schlimm, da der User
 * ja selbst eine Auswahl treffen kann.
 *
 * Wichtig ist nur, dass EPDM ausgewählt wird, falls nichts übergeben wird. Dies kann natürlich
 * passieren.
 */

/*
 * Der Rohrinnendurchmesser ist entweder 100, oder der übergebene Wert.
 * Dann noch eine Plausibilitätsprüfung machen.
 * D.h. er darf nicht kleiner sein als 30 und nicht größer als 800
 */
let rohrinnendurchmesser = parseInt(params.get("sw6_rohrinnendurchmesser")) ?? 100;

/*
 * Wenn nichts übergeben wurde (oder ein "falscher" Wert), dann irgendwas "in der Mitte" nehmen.
 */
if (isNaN(rohrinnendurchmesser)) {
  rohrinnendurchmesser = 100;
}

if (rohrinnendurchmesser < 30) {
  rohrinnendurchmesser = 30;
}
if (rohrinnendurchmesser > 800) {
  rohrinnendurchmesser = 800;
}

/*
 * Gibt es einen Flansch, oder nicht?
 */
let flanschParam = params.get("sw6_flansch") ?? "false";
let flansch = "nein";
if (flanschParam === "true") {
  flansch = "ja";
}

let flanschDurchmesser = parseInt(params.get("sw6_flanschdurchmesser")) ?? 260;

/*
 * Wenn nichts übergeben wurde (oder ein "falscher" Wert), dann irgendwas "in der Mitte" nehmen.
 */
if (isNaN(flanschDurchmesser)) {
  flanschDurchmesser = 260;
}

if (flanschDurchmesser < 30) {
  flanschDurchmesser = 30;
}
//if (flanschDurchmesser > 800) {
//  flanschDurchmesser = 800;
//}

/*
 * Die Anzahl der Leitungen innerhalb der Dichtung ist entweder 1 oder der übergebene Wert.
 * Dann noch eine Plausibilitätsprüfung machen.
 * D.h. er darf nicht kleiner sein als 0 und nicht größer als 100
 */
let anzahlLeitungen = parseInt(params.get("sw6_anzahl_rohre") ?? 1);

if (isNaN(anzahlLeitungen)) {
  anzahlLeitungen = 1;
}

if (anzahlLeitungen < 0) {
  anzahlLeitungen = 0;
}
if (anzahlLeitungen > 100) {
  anzahlLeitungen = 100;
}

let schraubenGroesse = 6;
if (rohrinnendurchmesser > 150) {
  schraubenGroesse = 8;
}
if (rohrinnendurchmesser > 300) {
  schraubenGroesse = 10;
}

//https://konfigurator.kroener-dichtungstechnik.de/?sw6_rohrinnendurchmesser=300&sw6_kernbohrungDurchmesser=250&sw6_anzahl_rohre=4&sw6_d1=12&sw6_d2=12&sw6_d3=12&sw6_d4=12&sw6_flansch=true&sw6_flanschdurchmesser=320
/*
 * Für die Anzahl der Leitungen KANN es noch jeweils einen Durchmesser geben.
 * Falls ja, dann merken. Falls nein, dann nehmen wir 0 als Durchmesser
 */
let leitungsDurchmesser = [];
for (let i = 0; i < anzahlLeitungen; i++) {
  leitungsDurchmesser[i] = parseInt(params.get("sw6_d" + (i+1)) ?? 0);
}

/*
 * Die Default - Daten
 */
let data =
    {
      diameter: rohrinnendurchmesser,
      heightElastomer: 40,
      elastomerType: elastomerMaterial,
      maxPressure: 0.1,
      screwWidth: schraubenGroesse,
      insertSize: "100",
      screwType: "Schlossschraube",
      flange: flansch,
      flangeWidth: flanschDurchmesser,
      nuts: "Sechskant",
      forceNumberScrews: "",
      username: this?.getCookie('username') ?? '',
      component: 'Dichtung',
      orderAmount: 1,
      pressureInput: false,
      splitSeal: false,
      pipes: [], // noch leer, wird gleich gefüllt
      maxPressures: [0.1, 0.3, 1.0]
    };

/*
 * Jetzt die Leitungen innerhalb der Dichtung setzen
 */
for (let i = 0; i < anzahlLeitungen; i++) {
  data.pipes.push({
    index: data.pipes.length, // 0..n-1
    diameter: leitungsDurchmesser[i],
  });
}

export default {
  name: "ParameterForm",
  data() {
    return data;
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    showPotentials() {
      return this.$store.state.showPotentials;
    },
    showPaths() {
      return this.$store.state.showPaths;
    },
    config() {
      return this.$store.state.config;
    },
    users() {
      return this.$store.state.users;
    },
    /*
     * Liefert true zurück, WENN es sich um einen Kunden liefert.
     */
    isCustomer() {
      /*
       * Wenn es sich um "localhost" handelt, sind wir im Programmieren. Dann bestimmt der Parameter
       * "Kundennummer" ob es ein Kunde ist, oder eben nicht.
       * (hostname liefert nur den Namen. host würde den Port mit liefern)
       */
      if (document.location.hostname.toLowerCase() === 'localhost') {
        return this.$route.query.sw6_kdnr !== undefined && this.$route.query.sw6_kdnr.length > 0;
      }
      /*
       * Wenn es sich um die im Internet verfügbare KUNDEN-URL handelt, ist es der Kunden-Modus
       */
      if (document.location.hostname.toLowerCase() === 'konfigurator.kroener-dichtungstechnik.de') {
        return true;
      }
      /*
       * Wenn es sich um die im Internet verfügbare INTERNE-URL handelt, ist es der NICHT Kunden-Modus
       */
      if (document.location.hostname.toLowerCase() === 'konfigurator-intern.kroener-dichtungstechnik.de') {
        return false;
      }

      /*
       * Ansonsten sicherheitshalber die eingeschränkte Kunden-GUI anzeigen
       */
      return false;
    },
    elastomerWerkstoffAuswahl() {
      let auswahl = {};

      /*
       * Über sämtliche möglichen Werkstoffe gehen und diese zur Auswahl hinzufügen.
       * ACHTUNG!
       * Diese Methode wird mehrfach aufgerufen.
       * Unter anderem auch dann, wenn die Config noch gar nicht existiert!
       */
      if (this.config?.components?.Elastomer) {
        this.config.components.Elastomer.Auswahl.Werkstoff.forEach(
            stoff => {
              /*
               * Das Backoffice liefert mir die Materalien.
               * Wir müssen hier noch für die Endkunden eine kleine Beschreibung einfügen...
               */
              let text = stoff;
              switch (stoff) {
                case "EPDM":
                  text += " (Wasser/Abwasser OHNE Mineralöl)";
                  break;
                case "SBR":
                  text += " (Nur nach Absprache)";
                  break;
                case "NBR":
                  text += " (Mineralölhaltige Medien)";
                  break;
                case "Silikon":
                  text += " (Nur nach Absprache)";
                  break;
              }
              auswahl[stoff] = text;
            }
        )
      }
      return auswahl;
    },
    screwOptions() {
      /*
       * Sämtliche vorhandene Schraubengrößen als Auswahl zurückliefern.
       */
      let screws = {};
      for (let screw in this.config.screws) {
        screws[screw] = this.config.screws[screw].Schraube[
            "Gewinde-Nenngroesse"
            ];
      }
      return screws;
    },
    buildDate() {
      return document.documentElement.dataset.buildTimestampUtc;
    },
  },
  methods: {
    /*
     * Fügt eine neue Leitung hinzu
     */
    addPipe() {
      // Es soll kein Außendurchmesser vorgegeben werden. Da dies nicht geht (number) => 0
      let durchmesser = 0;
      /*
       * Sollte es mindestens EINE Leitung geben, dann hat die neue Leitung den Durchmesser der "letzten"
       * Leitung. Dies hilft bei der Eingabe.
       * Laut Mail von Frau Klotz vom 21.04.2023 wieder entfernt.
       */
      //if (this.pipes.length >= 1) {
      //    durchmesser = this.pipes[this.pipes.length - 1].diameter;
      //}
      /*
       * Leitung hinzufügen
       */
      this.pipes.push({
        index: this.pipes.length,
        diameter: durchmesser,
      });
      /*
       * Und fokussieren.
       */
      setTimeout(() => {
        const elements = document.getElementsByClassName("pipe-input");
        elements[elements.length - 1].focus();
      }, 200);
    },
    /*
     * Löscht die Eingaben des Formulars bzw. setzt sie zurück.
     * AKTUELL nur die des (verkleinerten) Kunden-Formulars
     */
    deleteInputs() {
      /*
       * Die Leitungen komplett löschen...
       * (genauer gesagt, es gibt EINE LEitung ohne Durchmesser...)
       */
      this.pipes = [{
        index: 0, // index 0..n-1, also erstes Element = index 0
        diameter: 0, // Durchmesser ist 0
      }];
      /*
       * Die Werte setzen, die laut Herrn Simon die am häufigsten gewählten Werte sind
       * (Telefonat 13.04.2023)
       */
      this.diameter = 100;
      this.flange = "nein";
      this.maxPressure = 0.1;
      this.component = "Dichtung";
      this.elastomerType = "EPDM";
    },
    removePipe(index) {
      if (index > -1) {
        this.pipes.splice(index, 1);
      }
    },
    splitSealClick() {
      this.splitSeal = true;
      this.simulate();
      this.splitSeal = false;
    },
    switchPressure(event) {
      if (!event.target) {
        return;
      }
      if (event.target.value === 'mehr') {
        this.pressureInput = true;
        this.maxPressure = 1;
      }
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    /*
     * Zeigt die Hilfe zu einem Feld an.
     * Dazu wird das aktive Feld einfach innerhalb des Stores gesetzt.
     * Die Hilfe-Komponente zeigt dann (anhand von Conditions) die zur aktuellen
     * Eingabe passenden Hilfe-Texte an.
     * Zusätzlich wird noch das aktive Tab auf "Hilfe" gesetzt, d.h. die Hilfe wird auch
     * sichtbar.
     */
    zeigeHilfe(feldName) {
      // Aktive Tab setzen (Hilfe anzeigen)
      this.$store.state.activeTab = "Hilfe";
      // Aktives Feld setzen, damit die passende Hilfe angezeigt wird.
      this.$store.state.activeComponent = feldName;
    },
    /*
     * Ruft das Backoffice auf und versucht damit, die Dichtung zu "berechnen".
     */
    simulate() {
      // if (!this.$route.query?.sw6_kdnr){
      //   return;
      // }
      this.setCookie('username', this.username, 365);
      var request = {
        circles: [],
        radius: (parseFloat(this.diameter) / 2),
        heightElastomer: this.heightElastomer,
        elastomerType: this.elastomerType,
        screwType: this.screwType,
        flange: this.flange === "ja" ? this.flangeWidth / 2 : false,
        nuts: this.nuts,
        screwWidth: this.screwWidth,
        maxPressure: parseFloat(this.maxPressure),
        showPaths: this.showPaths,
        forceNumberScrews: this.forceNumberScrews,
        customerNumber: this.$route.query.sw6_kdnr,
        username: this.username,
        orderAmount: this.orderAmount,
        component: this.component,
        insertSize: this.insertSize,
        contact: this.splitSeal,
      };
      this.pipes.forEach((pipe) => {
        /*
         * Nur die Leitungen hinzufügen, die auch einen Durchmesser (größer 0) haben.
         */
        if (pipe.diameter > 0) {
          request.circles.push({
            x: null,
            y: null,
            r: parseFloat(pipe.diameter) / 2,
            f: -1,
          });
        }
      });

      /*
       * Und los...
       */
      this.$emit("simulate", request);
    },
  },
  watch: {
    showPotentials: function () {
      this.simulate();
    },
  },
};
</script>
