<template>
  <div class="mb-5">
    <p class=" mt-5 my-3 font-bold">Positionen der Schrauben und Rohre</p>
    <table class="min-w-full divide-y divide-gray-200 text-xs text-left">
      <thead class="bg-gray-50">
        <th
          scope="col"
          class="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
        >
          Element
        </th>
        <th
          scope="col"
          class="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
        >
          Position
        </th>
        <th
          scope="col"
          class="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
        >
          Durchmesser
        </th>
      </thead>
      <tbody
        class="bg-white divide-y divide-gray-200 text-left text-sm text-gray-900"
      >
        <tr v-for="(circle, index) in circles" :key="index">
          <td class="p-2">
            {{ getLabel(circle.f, circles, index) }}
          </td>
          <td class="p-2">
            {{ circle.x.toFixed(2) }},
            {{ circle.y.toFixed(2) }}
          </td>
          <td class="p-2">{{ circle.r * 2 }}</td>
        </tr>
      </tbody>
    </table>
    <p class=" mt-5 my-3 font-bold">Zusammenfassung</p>
    <div
      class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg w-full"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Wert
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Einheit
          </th>
        </thead>
        <tbody
          class="bg-white divide-y divide-gray-200 text-left text-sm text-gray-900"
        >
          <tr>
            <td class="p-2">hydraulische Fläche der Verpressung</td>
            <td class="p-2">
              {{ this.dimensioning.hydraulicAreaOfPressure.toFixed(2) }}
            </td>
            <td>mm²</td>
          </tr>
          <tr>
            <td class="p-2">Zu verpressende Fläche (Spalte)</td>
            <td class="p-2">
              {{ this.dimensioning.totalGapArea.toFixed(2) }}
            </td>
            <td>mm²</td>
          </tr>
          <tr>
            <td class="p-2">zu verpressendes Volumen (Hohlraum)</td>
            <td class="p-2">
              {{ this.dimensioning.volumeToPress.toFixed(2) }}
            </td>
            <td>mm³</td>
          </tr>
          <tr>
            <td class="p-2">
              zur verfügungstehendes Elastomervolumen = Elastomervolumen -
              Bohrungsvolumen
            </td>
            <td class="p-2">
              {{ this.dimensioning.availableElastomerVolume.toFixed(2) }}
            </td>
            <td>mm³</td>
          </tr>
          <tr>
            <td class="p-2">
              Verpressung Ausgangszustand = unmontiert (negative Werte bedeuten
              keine Verpressung von Anfang an = Fügen ohne Kraft)
            </td>
            <td class="p-2">
              {{ (this.dimensioning.defaultPressing * 100).toFixed(2) }}
            </td>
            <td>%</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class=" mt-5 my-3 font-bold">Pressweg der Dichtung</p>
    <div
      class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg w-full"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Wert
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Einheit
          </th>
        </thead>
        <tbody
          class="bg-white divide-y divide-gray-200 text-left text-sm text-gray-900"
        >
          <tr>
            <td class="p-2">
              Sollverpressung (Zielverpressung
              {{ this.config.config.targetPressure * 100 }} %)
            </td>
            <td class="p-2">
              {{ this.dimensioning.pressStroke.toFixed(2) }}
            </td>
            <td>mm</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class=" mt-5 my-3 font-bold">
      Absicherung gegen Auspressen durch hydrostatischen Druck
    </p>
    <div
      class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg w-full"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Wert
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Einheit
          </th>
        </thead>
        <tbody
          class="bg-white divide-y divide-gray-200 text-left text-sm text-gray-900"
        >
          <tr>
            <td class="p-2">
              Reibungskoeffizient der Wand
            </td>
            <td class="p-2">
              {{ this.config.config.coefficientOfFriction }}
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="p-2">
              Maximaler Anpressdruck
            </td>
            <td class="p-2">
              {{ this.dimensioning.maxSurfacePressure.toFixed(2) }}
            </td>
            <td>bar</td>
          </tr>
          <tr>
            <td class="p-2">
              Radiale Presskraft
            </td>
            <td class="p-2">
              {{ this.dimensioning.radialPressingForce.toFixed(2) }}
            </td>
            <td>N</td>
          </tr>
          <tr>
            <td class="p-2">
              Flächenpressung radial
            </td>
            <td class="p-2">
              {{ this.dimensioning.radialSurfacePressure.toFixed(2) }}
            </td>
            <td>N/mm</td>
          </tr>
          <tr>
            <td class="p-2">
              Anzahl Schrauben
            </td>
            <td class="p-2">{{ this.dimensioning.numberOfScrews }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="p-2">
              Belastung pro Schraube
            </td>
            <td class="p-2">
              {{ this.dimensioning.pressurePerScrew.toFixed(2) }}
            </td>
            <td>N</td>
          </tr>
          <tr>
            <td class="p-2">
              Kerndurchmesser der Schraube
            </td>
            <td class="p-2">{{ this.dimensioning.coreDiameterOfScrew }}</td>
            <td>mm</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class=" mt-5 my-3 font-bold">Anzugsmoment der Schrauben</p>
    <div
      class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg w-full"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Wert
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Einheit
          </th>
        </thead>
        <tbody
          class="bg-white divide-y divide-gray-200 text-left text-sm text-gray-900"
        >
          <tr>
            <td class="p-2">
              Anzugsmoment der Schrauben (
              {{ this.dimensioning.numberOfScrews }} x V2A)
            </td>
            <td class="p-2">
              {{ this.dimensioning.boltingTorque.toFixed(2) }}
            </td>
            <td>Nm</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "DimensioningTable",
  data() {
    return {};
  },
  computed: {
    dimensioning() {
      return this.$store.state.dimensioning;
    },
    config() {
      return this.$store.state.config;
    },
    circles() {
      return this.$store.state.circles;
    },
  },
  methods: {
    getLabel(f, circles, index) {
      var label = 0;
      for (var i = 0; i <= index; i++) {
        if (circles[i].f === f && i <= index) {
          label++;
        }
      }
      if (f === 1) {
        label = "s" + label;
      } else {
        label = "r" + label;
      }
      return label;
    },
  },
};
</script>
