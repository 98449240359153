<template>
  <div>
    <!-- Hilfe, wenn KEIN spezielles Feld ausgewählt wurde -->
    <div class="mb-5" v-if="aktivesFeld === ''">
      <p class="text-lg mt-10 text-gray-400">
      Bitte spezifizieren Sie die gewünschte Dichtung.<br>
        <br>
      Sollte es eine weiterführende Hilfe zu einem speziellen Eingabefeld geben, so wird Ihnen diese
      angezeigt, solange Sie sich innerhalb dieses Feldes befinden.
      </p>
    </div>
    <!-- Hilfe des Feldes Diameter -->
    <div class="mb-5" v-if="aktivesFeld === 'diameter'">
      <p class="text-lg mt-10 mb-10 text-gray-400">
        Innendurchmesser der Kernbohrung oder des Futterrohrs.
      </p>
      <img src="help/kernbohrung-innendurchmesser.jpg" alt="">
    </div>
    <!-- Maximaler Druck -->
    <div class="mb-5" v-if="aktivesFeld === 'maxPressure'">
      <p class="text-lg mt-10 mb-10 text-gray-400">
        Weitere Informationen zur Planung von Gebäudeeinführungen finden Sie unter folgendem Link:
        <a href="https://fhrk.de/wp-content/uploads/2023/03/Planungshilfe_05_2022_v3_220726_WEB-1.pdf"
        class="text-blue-600" target="kroener_ph">
          https://fhrk.de/wp-content/uploads/2023/03/Planungshilfe_05_2022_v3_220726_WEB-1.pdf
        </a>
      </p>
    </div>
    <!-- Hilfe des Feldes Flanschdurchmesser -->
    <div class="mb-5" v-if="aktivesFeld === 'flangeWidth'">
      <p class="text-lg mt-10 mb-10 text-gray-400">
        Außendurchmesser Flansch.
      </p>
      <img src="help/flanschdurchmesser.jpg" alt="">
    </div>
    <!-- Der Außendurchmesser der Leitungen -->
    <div class="mb-5" v-if="aktivesFeld === 'pipe-input'">
      <p class="text-lg mt-10 mb-10 text-gray-400">
        Außendurchmesser Leitungen.
      </p>
      <img src="help/aussendurchmesser-leitungen.jpg#" alt="">
    </div>

  </div>
</template>

<script>
export default {
  name: "HilfeSystem",
  data() {
    return {};
  },
  computed: {
    aktivesFeld() {
      // Das aktive Feld ist innerhalb des Stores definiert.
      // Dadurch kann VUE auf die Änderungen des Wertes sofort reagieren.
      return this.$store.state.activeComponent ?? '';
    },
  },
};
</script>
