<template>
  <div
      class="flex flex-col justify-center text-center justify-items-center p-5 px-10 text-gray-800"
  >
    <div
        v-if="circles && circles.length"
        class="text-center flex flex-col place-items-center"
    >
      <div v-if="contactSales">
        Bitte kontaktieren Sie unseren Vertrieb
        <t-button class="mx-auto mt-5" @click="contactMail">Anfragen</t-button>
      </div>
      <div v-else>
        <div
            v-if="uploadSuccessful"
            class="flex justify-center place-items-center my-10"
        >
          <div class="w-20 h-20 rounded-full bg-green-100 flex justify-center place-items-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 0 24 24"
                width="48"
                class="fill-current text-green-500"
            >
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
            </svg>
          </div>
        </div>
        <div v-if="uploadSuccessful">
          <p class="text-xl font-bold text-green-500 ">
            Ihre Konfiguration ist machbar.
          </p>
          <p class="">
            Sie wird unter der Bezeichnung
            <pre class="text-xl">{{ drawingNumber }}</pre>
            für 4 Wochen bei uns zwischengespeichert.<br>
            Wenn keine Anfrage erfolgt, wird sie danach gelöscht.
          </p>
          <div v-if="!anfrageOk"  class="mt-6">
            Bitte kontaktieren Sie unseren Vertrieb.<br>
            <div class=" mt-8 text-sm text-gray-500 text-left">
              Bitte beachten Sie: Je mehr weitere Informationen Sie uns vorab mitteilen, desto schneller können wir
              Ihre Anfrage abwickeln.
            </div>
            <div class="mt-5 text-sm text-gray-500 text-left">
              Ihre E-Mail Adresse (benötigt)
            </div>
            <t-input
                v-model="anfrageEmail"
                required
            />

            <div class=" mt-5 text-sm text-gray-500 text-left">
              Ansprechpartner
            </div>
            <t-input
                v-model="anfrageAnsprechpartner"
                required
            />

            <div class=" mt-5 text-sm text-gray-500 text-left">
              Kundennummer bzw. Firmenadresse (falls noch keine Kundennummer vorhanden)
            </div>
            <t-textarea rows="6" class="mx-auto" v-model="anfrageAdresse"></t-textarea>

            <div class=" mt-5 text-sm text-gray-500 text-left">
              Abweichende Lieferanschrift (falls erwünscht)
            </div>
            <t-textarea rows="6" class="mx-auto" v-model="anfrageLieferAnschrift"></t-textarea>

            <div class=" mt-5 text-sm text-gray-500 text-left">
              Weitere Anmerkungen
            </div>
            <t-textarea rows="6" class="mx-auto" v-model="anfrageAnmerkungen"></t-textarea>
            <div class="mt-6">
              <t-button class="mx-auto mt-5" @click="contactMail">Anfragen</t-button>
            </div>
          </div>
          <p v-if="anfrageError" class="text-xl font-bold text-red-500 mt-5">
            Beim Versenden Ihrer Anfrage ist leider ein Fehler aufgetreten.<br>
            Bitte versuchen Sie es in ein paar Minuten erneut.
          </p>
          <p v-if="anfrageOk" class="text-xl font-bold text-green-500 mt-5">
            Ihre Anfrage wurde erfolgreich an uns versendet.<br>
            Wir melden uns schnellst möglichst bei Ihnen.
          </p>

          <!--p class="text-xl font-bold text-yellow-400">
            Konstruktion läuft...
          </p-->
        </div>
        <p v-if="!uploadSuccessful" class="text-xl font-bold text-red-500 mt-5">
          Fehler bei Dateiupload
        </p>
        <div
            v-if="!uploadSuccessful"
            @click="downloadXml()"
            class="cursor-pointer max-w-xs mx-auto flex flex-col text-center justify-center px-4 py-3 my-5 text-sm text-blue-100 transition-colors duration-150 bg-blue-500 rounded focus:shadow-outline hover:bg-blue-800 shadow-md"
        >
          Download CAD Datei
          <br/><small>{{ drawingNumber }}.xml</small>
        </div>
        <div v-if="uploadSuccessful">
          <div v-if="false" class="mt-10 flex justify-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="48"
                viewBox="0 0 24 24"
                width="48"
                class="fill-current text-gray-500"
            >
              <g>
                <rect fill="none" height="24" width="24"/>
              </g>
              <g>
                <g>
                  <g>
                    <path
                        d="M21.67,18.17l-5.3-5.3h-0.99l-2.54,2.54v0.99l5.3,5.3c0.39,0.39,1.02,0.39,1.41,0l2.12-2.12 C22.06,19.2,22.06,18.56,21.67,18.17z"
                    />
                  </g>
                  <g>
                    <path
                        d="M17.34,10.19l1.41-1.41l2.12,2.12c1.17-1.17,1.17-3.07,0-4.24l-3.54-3.54l-1.41,1.41V1.71L15.22,1l-3.54,3.54l0.71,0.71 h2.83l-1.41,1.41l1.06,1.06l-2.89,2.89L7.85,6.48V5.06L4.83,2.04L2,4.87l3.03,3.03h1.41l4.13,4.13l-0.85,0.85H7.6l-5.3,5.3 c-0.39,0.39-0.39,1.02,0,1.41l2.12,2.12c0.39,0.39,1.02,0.39,1.41,0l5.3-5.3v-2.12l5.15-5.15L17.34,10.19z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div v-if="false">
            <p class="mt-5 font-bold text-lg">Installations-Anweisungen</p>
            <p class="my-2 text-sm">
              Zur Abdichtung Ihrer
              {{ circles.length - dimensioning.numberOfScrews }} Rohr(e) gegen
              {{ this.dimensioning.maxPressure }} bar Druck werden
              {{ dimensioning.numberOfScrews }} x M{{ screwWidth }}
              Schrauben benötigt.
            </p>
            <p class="my-2 text-sm">
              Um eine Zielverpressung von
              {{ this.config.config.targetPressure * 100 }} % zu erreichen, sollte der
              Pressweg 'P' bei {{ this.dimensioning.pressStroke.toFixed(2) }} mm bezogen
              der Ausgangslänge liegen.
            </p>
            <p v-if="this.dimensioning.boltingTorque" class="my-2 text-sm">
              Die Schrauben sollten mit einem Schraubenanzugsmoment von
              {{ this.dimensioning.boltingTorque.toFixed(2) }} Nm angezogen werden.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-lg mt-10 text-gray-400">
        Bitte spezifizieren Sie die gewünschte Dichtung.
      </p>
    </div>
  </div>
</template>

<script>

var FileSaver = require("file-saver");

export default {
  name: "ResultOverview",
  data() {
    return {
      anfrageAdresse: "",
      anfrageLieferAnschrift: "",
      anfrageAnmerkungen: "",
      anfrageEmail: "",
      anfrageAnsprechpartner: "",
    };
  },
  computed: {
    circles() {
      return this.$store.state.circles;
    },
    outerCircle() {
      return this.$store.state.outerCircle;
    },
    dimensioning() {
      return this.$store.state.dimensioning;
    },
    config() {
      return this.$store.state.config;
    },
    uploadSuccessful() {
      return this.$store.state.uploadSuccessful;
    },
    xsrfToken() {
      return this.$store.state.xsrfToken;
    },
    contactSales() {
      return this.$store.state.contact;
    },
    inputData() {
      return this.$store.state.inputData;
    },
    xml() {
      return this.$store.state.xml;
    },
    drawingNumber() {
      return this.$store.state.drawingNumber;
    },
    screwWidth() {
      for (var i = 0; i < this.circles.length; i++) {
        if (this.circles[i].f === 1) {
          return this.circles[i].r * 2;
        }
      }
      return false;
    },
    anfrageError() {
      return this.$store.state.anfrageError;
    },
    anfrageOk() {
      return this.$store.state.anfrageOk;
    },
  },
  methods: {
    getLabel(f, circles, index) {
      var label = 0;
      for (var i = 0; i <= index; i++) {
        if (circles[i].f === f && i <= index) {
          label++;
        }
      }
      if (f === 1) {
        label = "s" + label;
      } else {
        label = "r" + label;
      }
      return label;
    },
    contactMail() {
//      console.log("Hallo2");
//      console.log(this.inputData);
//      console.log(this.xsrfToken);
//      console.log("Hallo3");
      if (this.anfrageEmail === '') {
        alert("Bitte geben Sie Ihre E-Mail Adresse ein.");
        return;
      }
      // POST request using fetch with error handling *
      let zielUrl = (process.env.NODE_ENV === "development") ? 'http://localhost:4080' : 'https://anfrage.konfigurator.kroener-dichtungstechnik.de';
      //console.log(zielUrl);
      fetch(zielUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: this.xsrfToken,
          email: this.anfrageEmail,
          adresse: this.anfrageAdresse,
          ansprechpartner: this.anfrageAnsprechpartner,
          lieferanschrift: this.anfrageLieferAnschrift,
          anmerkungen: this.anfrageAnmerkungen,
          subject: "Kontaktanfrage Konfigurator Dichtung Rohrinnendurchmesser " + this.inputData.radius * 2 + " mm (" + this.drawingNumber + ")",
          content: "Produkt: " + this.inputData.component + "\n" +
          "Anzahl: " + this.inputData.orderAmount + "\n" +
          "Rohrinnendurchmesser: " + this.inputData.radius * 2 + "\n" +
          "Druck: " + this.inputData.maxPressure + "\n" +
          "Material des Elastomers: " + this.inputData.elastomerType + "\n" +
          "Flansch: " + (((this.inputData.flange === false) || (this.inputData.flange === 'false')) ? 'Nein' : 'Ja') + "\n" +
              this.inputData.circles.map((circle, index) => {
                return "\nLeitung " + (index + 1) + ": " + (circle.r * 2);
              })
        })
      }).then(async response => {
        const data = await response.json();
        if (data.error != null) {
          if (data.error === -100) {
           alert('Bitte geben Sie eine gültige E-Mail Adresse ein.')
           return;
          }
          //console.log('Error');
          this.$store.state.anfrageError = true;
          this.$store.state.anfrageOk = false;
          return;
        }
        this.$store.state.anfrageError = false;
        this.$store.state.anfrageOk = true;
      })
          .catch(error => {
            console.error('There was an error!', error);
            this.$store.state.anfrageError = true;
            this.$store.state.anfrageOk = false;

          });
    },
    downloadXml() {
      var blob = new Blob([this.xml], {
        type: "text/xml;charset=utf-8",
      });
      FileSaver.saveAs(blob, this.drawingNumber + ".xml");
    },
  },
};
/*
//document.location.href = `
mailto: vertrieb
@kroener
-dichtungstechnik.de ? subject = Kontaktanfrage % 20Konfigurator % 20
geteilte % 20
Dichtung % 20
Rohrinnendurchmesser % 20${this.inputData.radius * 2} % 20
mm % 20(${this.drawingNumber}) & body = Name % 3
A % 20 % 0
ATelefonnummer % 3
A % 20 % 0
A % 0
AProdukt % 3
A % 20${this.inputData.component} % 0
AAnzahl % 3
A % 20${this.inputData.orderAmount} % 0
ARohrinnendurchmesser % 3
A % 20${this.inputData.radius * 2} % 0
ADruck % 3
A % 20${this.inputData.maxPressure} % 0
AMaterial % 20
des % 20
Elastomers % 3
A % 20${this.inputData.elastomerType} % 0
AFlansch % 3
A % 20${((this.inputData.flange === false) || (this.inputData.flange === 'false')) ? 'Nein':'Ja'}${this.inputData.circles.map((circle, index) => { return `%0ALeitung%20${index + 1} % 3
A % 20${circle.r * 2}` })}`;

 */
</script>
