import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: false,
    loading: false,
    users: false,
    outerCircle: null,
    circles: [],
    potentials: [],
    error: null,
    runs: null,
    activity: null,
    dimensioning: null,
    prices: null,
    mode3d: false,
    showPotentials: false,
    showPaths: false,
    showVectors: false,
    ticks: [],
    xml: false,
    drawingNumber: false,
    uploadSuccessful: false,
    // Das Token für die Anfrage
    xsrfToken: "",
    // gab es einen Fehler bei der Anfrage
    anfrageError : false,
    // War die Anfrafge O.K.
    anfrageOk : false,
    contact: false,
    inputData: null,
    // Die Komponente, die aktuell den Fokus besitzt
    activeComponent: "",
    // Der aktive Tab
    activeTab : "",
  },
  mutations: {
    setConfig(state, value) {
      state.config = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setUsers(state, value) {
      state.users = value;
    },
    setSimulationResult(state, value) {
      state.circles = value.circles;
      state.outerCircle = value.outerCircle;
      state.runs = value.runs;
      state.activity = value.activity;
      state.dimensioning = value.dimensioning;
      state.xml = value.xml;
      state.drawingNumber = value.drawingNumber;
      state.prices = value.prices;
      state.uploadSuccessful = value.upload;
      state.contact = value.contact;
      state.inputData = value.inputData;
      state.xsrfToken = value.xsrfToken;
      state.anfrageError = false;
      state.anfrageOk = false;
    },
    setPotentials(state, value) {
      state.potentials = value;
    },
    setError(state, value) {
      state.error = value;
    },
    setAnfrageError(state, value) {
      state.anfrageError = value;
    },
    setAnfrageOk(state, value) {
      state.anfrageOk = value;
    },
    setDimensioning(state, value) {
      state.dimensioning = value;
    },
    setPrices(state, value) {
      state.prices = value;
    },
    setMode3d(state, value) {
      state.mode3d = value;
    },
    setShowPotentials(state, value) {
      state.showPotentials = value;
    },
    setShowPaths(state, value) {
      state.showPaths = value;
    },
    setShowVectors(state, value) {
      state.showVectors = value;
    },
    setActiveComponent(state, value) {
      state.activeComponent = value;
    },
    setActiveTab(state, value) {
      state.activeTab = value;
    },


  },
  actions: {
    setConfig(context, value) {
      context.commit("setConfig", value);
    },
    setUsers(context, value){
      context.commit("setUsers", value);
    },
    setLoading(context, value) {
      context.commit("setLoading", value);
    },
    setSimulationResult(context, value) {
      context.commit("setSimulationResult", value);
    },
    setPotentials(context, value) {
      context.commit("setPotentials", value);
    },
    setError(context, value) {
      context.commit("setError", { message: value, severity: "Error" });
    },
    setAnfrageError(context, value) {
      context.commit("setAnfrageError", value);
    },
    setAnfrageOk(context, value) {
      context.commit("setAnfrageOk", value);
    },
    setWarning(context, value) {
      context.commit("setError", { message: value, severity: "Warning" });
    },
    removeError(context) {
      context.commit("setError", false);
    },
    setDimensioning(context, value) {
      context.commit("setDimensioning", value);
    },
    setPrices(context, value) {
      context.commit("setPrices", value);
    },
    setMode3d(context, value) {
      context.commit("setMode3d", value);
    },
    setShowPotentials(context, value) {
      context.commit("setShowPotentials", value);
    },
    setShowPaths(context, value) {
      context.commit("setShowPaths", value);
    },
    setShowVectors(context, value) {
      context.commit("setShowVectors", value);
    },
    setActiveComponent(context, value) {
      context.commit("activeComponent", value);
    },
    setActiveTab(context, value) {
      context.commit("activeTab", value);
    },
  },
  modules: {},
});
