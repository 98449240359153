import svgJS from "svg.js";
/* eslint-disable */
import "svg.panzoom.js";
/* eslint-enable */

export default {
  install: (Vue) => {
    Vue.prototype.$svg = svgJS;
  },
};
