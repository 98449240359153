import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import svgJS from "@/plugins/svg.js";


import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleQuestion);

import "./assets/styles/index.css";
import "./assets/styles/tailwind.css";

import VueTailwind from "vue-tailwind";

import {
  TInput,
  TTextarea,
  TButton,
  TAlert,
  TCheckbox,
  TToggle,
  TSelect,
} from "vue-tailwind/dist/components";

const settings = {
  "t-input": {
    component: TInput,
    props: {
      classes: "border-2 block w-full rounded text-gray-800",
      // ...More settings
    },
  },
  "t-textarea": {
    component: TTextarea,
    props: {
      //fixedClasses:
        //"block w-full px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
      classes:
        "border-2 block w-full rounded text-gray-800",
      variants: {
        danger: "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
        success:
          "border-green-300 bg-green-50 placeholder-gray-400 text-green-900",
      },
    },
  },
  "t-button": {
    component: TButton,
    props: {
      fixedClasses:
        "block px-4 py-2 transition duration-100 ease-in-out focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
      classes:
        "text-white bg-blue-500 border border-transparent shadow-sm rounded hover:bg-blue-600",
      variants: {
        secondary:
          "text-gray-800 bg-white border border-gray-300 shadow-sm hover:text-gray-600",
        error:
          "text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600",
        success:
          "text-white bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600",
        link: "text-blue-500 underline hover:text-blue-600",
      },
    },
  },
  "t-button-secondary": {
    component: TButton,
    props: {
      fixedClasses:
        "block px-4 py-2 transition duration-100 ease-in-out focus:border-gray-500 focus:ring-2 focus:ring-gray-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
      classes:
        "text-white bg-gray-500 border border-transparent shadow-sm rounded hover:bg-gray-600",
      variants: {
        secondary:
          "text-gray-800 bg-white border border-gray-300 shadow-sm hover:text-gray-600",
        error:
          "text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600",
        success:
          "text-white bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600",
        link: "text-gray-500 underline hover:text-gray-600",
      },
    },
  },
  "t-alert": {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: "relative flex items-center p-4 border-l-4  rounded shadow-sm",
        body: "flex-grow",
        close:
          "absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded  focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        closeIcon: "fill-current h-4 w-4",
      },
      classes: {
        wrapper: "bg-blue-50 border-blue-500",
        body: "text-blue-700",
        close: "text-blue-500 hover:bg-blue-200",
      },
      variants: {
        danger: {
          wrapper: "bg-red-50 border-red-500",
          body: "text-red-700",
          close: "text-red-500 hover:bg-red-200",
        },
        success: {
          wrapper: "bg-green-50 border-green-500",
          body: "text-green-700",
          close: "text-green-500 hover:bg-green-200",
        },
        warning: {
          wrapper: "bg-yellow-50 border-yellow-500",
          body: "text-yellow-700",
          close: "text-yellow-500 hover:bg-yellow-200",
        },
      },
    },
  },
  "t-checkbox": {
    component: TCheckbox,
    props: {
      fixedClasses:
        "transition duration-100 ease-in-out rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-blue-500 border-gray-300 ",
      variants: {
        error: "text-red-500 border-red-300",
        success: "text-green-500 border-green-300",
      },
    },
  },
  "t-select": {
    component: TSelect,
    props: {
      fixedClasses:
        "block w-full pl-3 pr-10 py-2 transition duration-100 ease-in-out border-2 rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
      classes:
        "text-black placeholder-gray-400 bg-white border-gray-300 focus:border-blue-500",
      variants: {
        danger: "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
        success:
          "border-green-300 bg-green-50 placeholder-gray-400 text-green-900",
      },
    },
  },
  "t-toggle": {
    component: TToggle,
    props: {
      fixedClasses: {
        wrapper:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200",
        wrapperChecked:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        wrapperDisabled:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed",
        wrapperCheckedDisabled:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        button:
          "inline-block absolute transform translate-x-0 transition ease-in-out duration-200",
        buttonChecked:
          "inline-block absolute transform translate-x-full transition ease-in-out duration-200",
        checkedPlaceholder: "inline-block",
        uncheckedPlaceholder: "inline-block",
      },
      classes: {
        wrapper:
          "bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        wrapperChecked: "bg-blue-500 rounded-full",
        wrapperDisabled:
          "bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        wrapperCheckedDisabled: "bg-blue-500",
        button:
          "h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs",
        buttonChecked:
          "h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-blue-500 text-xs",
        checkedPlaceholder:
          "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
        uncheckedPlaceholder:
          "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
      },
      variants: {
        danger: {
          wrapperChecked: "bg-red-500 rounded-full",
          wrapperCheckedDisabled: "bg-red-500 rounded-full",
        },
        success: {
          wrapperChecked: "bg-green-500 rounded-full",
          wrapperCheckedDisabled: "bg-green-500 rounded-full",
        },
        box: {
          wrapper:
            "bg-gray-100 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          wrapperChecked: "bg-blue-500 rounded-sm",
          wrapperCheckedDisabled: "bg-blue-500 rounded-sm",
          button:
            "h-6 w-6 rounded-sm bg-white shadow flex items-center justify-center text-gray-400 text-xs",
          buttonChecked:
            "h-6 w-6 rounded-sm  bg-white shadow flex items-center justify-center text-blue-500 text-xs",
          checkedPlaceholder:
            "rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs",
          uncheckedPlaceholder:
            "rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs",
        },
      },
    },
  },
};

Vue.use(VueTailwind, settings);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(svgJS);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

