<template>
  <div>
    <div id="3dViewer" v-show="loadedTextures >= 11"></div>
    <div v-show="loadedTextures < 11">
      <div class="lds-ring mt-32 md:mt-20">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div>{{ loadingMessage }} ({{ loadedTextures }}/11)</div>
    </div>
  </div>
</template>

<script>
var _ = require("lodash");
import * as THREE from "three";
import { CSG } from "@enable3d/three-graphics/jsm/csg";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
//@TODO: Das ist nur ein Workaround weil das hier nicht mehr geht: https://www.npmjs.com/package/three-csg-ts
export default {
  name: "View3d",
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      controls: null,
      textureCube: false,
      textureMetal: {},
      texturePlastic: {},
      loadedTextures: 0,
    };
  },
  async mounted() {
    await this.loadTextures();
    this.init();
    this.animate();
    this.drawEverything();
  },
  methods: {
    async loadTextures() {
      var urls = [
        require("@/assets/textures/posx.jpg"),
        require("@/assets/textures/negx.jpg"),
        require("@/assets/textures/posy.jpg"),
        require("@/assets/textures/negy.jpg"),
        require("@/assets/textures/posz.jpg"),
        require("@/assets/textures/negz.jpg"),
      ];
      this.textureCube = await new THREE.CubeTextureLoader().loadAsync(urls);
      this.loadedTextures++;
      this.textureMetal.map = await new THREE.TextureLoader().loadAsync(
        require("@/assets/textures/Metal038_1K_Color.jpg")
      );
      this.loadedTextures++;
      this.textureMetal.displacementMap = await new THREE.TextureLoader().loadAsync(
        require("@/assets/textures/Metal038_1K_Displacement.jpg")
      );
      this.loadedTextures++;
      this.textureMetal.metalnessMap = await new THREE.TextureLoader().loadAsync(
        require("@/assets/textures/Metal038_1K_Metalness.jpg")
      );
      this.loadedTextures++;
      this.textureMetal.normalMap = await new THREE.TextureLoader().loadAsync(
        require("@/assets/textures/Metal038_1K_Normal.jpg")
      );
      this.loadedTextures++;
      this.textureMetal.roughnessMap = await new THREE.TextureLoader().loadAsync(
        require("@/assets/textures/Metal038_1K_Roughness.jpg")
      );
      this.loadedTextures++;
      this.texturePlastic.map = await new THREE.TextureLoader().loadAsync(
        require("@/assets/textures/Plastic006_1K_Color.jpg")
      );
      this.loadedTextures++;
      this.texturePlastic.displacementMap = await new THREE.TextureLoader().loadAsync(
        require("@/assets/textures/Plastic006_1K_Displacement.jpg")
      );
      this.loadedTextures++;
      this.texturePlastic.normalMap = await new THREE.TextureLoader().loadAsync(
        require("@/assets/textures/Plastic006_1K_Normal.jpg")
      );
      this.loadedTextures++;
      this.texturePlastic.roughnessMap = await new THREE.TextureLoader().loadAsync(
        require("@/assets/textures/Plastic006_1K_Roughness.jpg")
      );
      this.loadedTextures++;
    },
    init: function() {
      let container = document.getElementById("3dViewer");

      this.camera = new THREE.PerspectiveCamera(70, 1, 0.01, 2000);
      this.camera.position.z = -200;

      this.scene = new THREE.Scene();

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setClearColor(0xffffff);
      this.renderer.setSize(700, 700);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      container.appendChild(this.renderer.domElement);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.update();

      var alight = new THREE.AmbientLight(0xffffff, 1.5);
      this.scene.add(alight);

      const light = new THREE.PointLight(0xffffff, 1, 0, 2);
      light.position.set(50, 200, 50);
      light.castShadow = true;
      this.scene.add(light);
      light.shadow.radius = 5;

      this.textureCube.format = THREE.RGBFormat;
    },
    animate: function() {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
      this.controls.update();
    },
    drawEverything() {
      let materialClearMetal = new THREE.MeshStandardMaterial({
        color: "silver",
        roughness: 0.1,
        metalness: 0.8,
        map: this.textureMetal.map,
        displacementMap: this.textureMetal.displacementMap,
        metalnessMap: this.textureMetal.metalnessMap,
        normalMap: this.textureMetal.normalMap,
        roughnessMap: this.textureMetal.roughnessMap,
        envMap: this.textureCube,
        envMapIntensity: 5.0,
      });

      let materialNormalMetal = new THREE.MeshStandardMaterial({
        color: "silver",
        roughness: 0.5,
        metalness: 0.9,
        map: this.textureMetal.map,
        displacementMap: this.textureMetal.displacementMap,
        metalnessMap: this.textureMetal.metalnessMap,
        normalMap: this.textureMetal.normalMap,
        roughnessMap: this.textureMetal.roughnessMap,
        envMap: this.textureCube,
        envMapIntensity: 5.0,
      });

      let materialRuggedMetal = new THREE.MeshStandardMaterial({
        color: "silver",
        roughness: 0.9,
        metalness: 0.3,
        map: this.textureMetal.map,
        displacementMap: this.textureMetal.displacementMap,
        metalnessMap: this.textureMetal.metalnessMap,
        normalMap: this.textureMetal.normalMap,
        roughnessMap: this.textureMetal.roughnessMap,
        envMap: this.textureCube,
        envMapIntensity: 0.5,
      });

      let materialElastomer = new THREE.MeshStandardMaterial({
        color: "gray",
        roughness: 0.8,
        metalness: 0.01,
        map: this.texturePlastic.map,
        displacementMap: this.texturePlastic.displacementMap,
        normalMap: this.texturePlastic.normalMap,
        roughnessMap: this.texturePlastic.roughnessMap,
      });

      var holes = [];
      if (this.circles && this.dimensioning) {
        this.circles.forEach((circle) => {
          if (circle.f === -1) {
            let geometry = new THREE.CylinderGeometry(
              circle.r,
              circle.r,
              120,
              25
            );
            let material = new THREE.MeshStandardMaterial({
              color: "gray",
              roughness: 0.8,
              metalness: 0.01,
              map: this.texturePlastic.map,
              displacementMap: this.texturePlastic.displacementMap,
              normalMap: this.texturePlastic.normalMap,
              roughnessMap: this.texturePlastic.roughnessMap,
              transparent: true,
              opacity: 0.5,
            });
            let mesh = new THREE.Mesh(geometry, material);
            mesh.position.set(circle.x, -20, circle.y);
            mesh.castShadow = true;
            mesh.receiveShadow = true;
            holes.push(_.cloneDeep(mesh));
            this.scene.add(mesh);
          }

          if (circle.f === 1) {
            /// Schrauben
            // oben
            let mesh = null;
            let geometry = null;

            if (this.dimensioning.screwType == "Innensechskant") {
              geometry = new THREE.CylinderGeometry(
                this.config.screws[circle.r * 2].Schraube["Kopfdurchmesser"] /
                  2,
                this.config.screws[circle.r * 2].Schraube["Kopfdurchmesser"] /
                  2,
                this.config.screws[circle.r * 2].Schraube.Hoehe,
                25
              );
              mesh = new THREE.Mesh(geometry);
              mesh.position.set(0, 0, 0);

              geometry = new THREE.CylinderGeometry(
                this.config.screws[circle.r * 2].Schraube[
                  "Aussendurchmesser Nuß 1/4"
                ] / 3,
                this.config.screws[circle.r * 2].Schraube[
                  "Aussendurchmesser Nuß 1/4"
                ] / 3,
                this.config.screws[circle.r * 2].Schraube.Hoehe + 2,
                6
              );
              let imbus = new THREE.Mesh(geometry);
              imbus.position.set(
                0,
                this.config.screws[circle.r * 2].Schraube.Hoehe / 2,
                0
              );
              mesh = CSG.subtract(mesh, imbus);
              mesh.material = materialClearMetal;
              mesh.castShadow = true;
              mesh.receiveShadow = true;
            } else {
              geometry = new THREE.CylinderGeometry(
                this.config.screws[circle.r * 2].Schraube[
                  "Aussendurchmesser Nuß 1/4"
                ] / 2,
                this.config.screws[circle.r * 2].Schraube[
                  "Aussendurchmesser Nuß 1/4"
                ] / 2,
                this.config.screws[circle.r * 2].Schraube.Hoehe,
                6
              );
              mesh = new THREE.Mesh(geometry, materialNormalMetal);
            }
            mesh.position.set(
              circle.x,
              this.config.config.thrustWasherTickness +
                this.config.screws[circle.r * 2].Unterlegscheibe.Hoehe,
              circle.y
            );
            mesh.castShadow = true;
            mesh.receiveShadow = true;
            this.scene.add(mesh);

            if (this.dimensioning.nuts == "Sechskant") {
              // unten
              geometry = new THREE.CylinderGeometry(circle.r, circle.r, 2, 25);
              mesh = new THREE.Mesh(geometry, materialNormalMetal);
              mesh.position.set(
                circle.x,
                -this.config.config.thrustWasherTickness * 2 -
                  this.dimensioning.heightElastomer -
                  this.config.screws[circle.r * 2].Unterlegscheibe.Hoehe,
                circle.y
              );
              mesh.castShadow = true;
              mesh.receiveShadow = true;
              this.scene.add(mesh);

              // Mutter
              geometry = new THREE.CylinderGeometry(
                this.config.screws[circle.r * 2].Schraube[
                  "Aussendurchmesser Nuß 1/4"
                ] / 2,
                this.config.screws[circle.r * 2].Schraube[
                  "Aussendurchmesser Nuß 1/4"
                ] / 2,
                this.config.screws[circle.r * 2].Schraube.Hoehe,
                6
              );
              mesh = new THREE.Mesh(geometry, materialNormalMetal);
              mesh.position.set(
                circle.x,
                -this.config.config.thrustWasherTickness -
                  this.dimensioning.heightElastomer -
                  this.config.screws[circle.r * 2].Unterlegscheibe.Hoehe,
                circle.y
              );
              mesh.castShadow = true;
              mesh.receiveShadow = true;
              this.scene.add(mesh);
            }

            // Unterlegscheiben
            geometry = new THREE.CylinderGeometry(
              this.config.screws[circle.r * 2].Unterlegscheibe
                .Aussendurchmesser / 2,
              this.config.screws[circle.r * 2].Unterlegscheibe
                .Aussendurchmesser / 2,
              this.config.screws[circle.r * 2].Unterlegscheibe.Hoehe,
              25
            );
            // Oben
            mesh = new THREE.Mesh(geometry, materialClearMetal);
            mesh.position.y = 2;
            mesh.position.x = circle.x;
            mesh.position.z = circle.y;
            mesh.castShadow = true;
            mesh.receiveShadow = true;
            this.scene.add(mesh);
            if (this.dimensioning.nuts == "Sechskant") {
              // Unten
              mesh = new THREE.Mesh(geometry, materialClearMetal);
              mesh.position.y =
                -this.config.config.thrustWasherTickness -
                this.dimensioning.heightElastomer +
                2;
              mesh.position.x = circle.x;
              mesh.position.z = circle.y;
              mesh.castShadow = true;
              mesh.receiveShadow = true;
              this.scene.add(mesh);
            }
          }
        });

        if (this.outerCircle) {
          this.camera.position.z =
            -(this.dimensioning.flange
              ? this.dimensioning.flange
              : this.outerCircle.r) * 2;

          let geometry = new THREE.CylinderGeometry(
            this.dimensioning.flange
              ? this.dimensioning.flange
              : this.outerCircle.r,
            this.dimensioning.flange
              ? this.dimensioning.flange
              : this.outerCircle.r,
            this.config.config.thrustWasherTickness,
            50
          );
          let mesh = new THREE.Mesh(geometry);
          mesh.position.set(0, 0, 0);
          for (var i = 0; i < holes.length; i++) {
            mesh = CSG.subtract(mesh, holes[i]);
            this.loadedTextures++;
          }
          mesh.material = materialRuggedMetal;
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          this.scene.add(mesh);

          geometry = new THREE.CylinderGeometry(
            this.outerCircle.r,
            this.outerCircle.r,
            this.config.config.thrustWasherTickness,
            50
          );
          mesh = new THREE.Mesh(geometry);
          mesh.position.set(0, -this.dimensioning.heightElastomer, 0);
          for (i = 0; i < holes.length; i++) {
            mesh = CSG.subtract(mesh, holes[i]);
            this.loadedTextures++;
          }
          mesh.material = materialRuggedMetal;
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          this.scene.add(mesh);

          geometry = new THREE.CylinderGeometry(
            this.outerCircle.r,
            this.outerCircle.r,
            this.dimensioning.heightElastomer,
            50
          );
          mesh = new THREE.Mesh(geometry);
          mesh.position.set(0, -(this.dimensioning.heightElastomer / 2), 0);
          for (i = 0; i < holes.length; i++) {
            mesh = CSG.subtract(mesh, holes[i]);
            this.loadedTextures++;
          }
          mesh.material = materialElastomer;
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          this.scene.add(mesh);
        }
      }
    },
  },
  computed: {
    circles() {
      return this.$store.state.circles;
    },
    loadingMessage() {
      if (this.loadedTextures < 9) {
        return "Lade Texturen";
      } else {
        return "Bohre Löcher";
      }
    },
    numberOfPipes() {
      var n = 0;
      this.circles.forEach((circle) => {
        if (circle.f === -1) n++;
      });
      return n;
    },
    config() {
      return this.$store.state.config;
    },
    outerCircle() {
      return this.$store.state.outerCircle;
    },
    dimensioning() {
      return this.$store.state.dimensioning;
    },
  },
  watch: {
    circles: function() {
      this.drawEverything();
    },
    outerCircle: function() {
      this.drawEverything();
    },
  },
};
</script>

<style scoped></style>
