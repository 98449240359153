<template>
  <div
    class="float-left py-2 px-4 mr-3 my-3 border-solid border-b-2 cursor-pointer"
    :class="this.active ? 'border-blue-500' : 'border-gray-200'"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  name: "TabArea",
  data() {
    return {};
  },
  props: {
    value: String,
    activeTab: String,
  },
  computed: {
    active: function() {
      return this.value === this.activeTab;
    },
  },
};
</script>
