<template>
  <div>
    <div class="block md:hidden text-sm px-5 pb-3">
      Zum Zoomen und Scrollen der Zeichnung auf mobilen Geräten zwei Finger
      nutzen.
    </div>

    <div class="relative">
      <div id="svg" class="relative" v-show="!mode3d"></div>
      <View3d class="relative" v-if="mode3d" outerCircle="inputOuterCircle" />
      <div class="absolute bg-white p-3 m-1 left-1 top-1 bg-opacity-80 shadow">
        <div class="flex flex-col">
          <label class="flex items-center mb-3">
            <t-toggle name="options" :checked="mode3d" @change="setMode3d" />
            <span class="ml-2 text-sm">3D-Modus</span>
          </label>
          <div v-show="!mode3d">
            <label class="flex items-center mb-3">
              <t-toggle
                name="options"
                :checked="showPotentials"
                @change="setShowPotentials"
              />
              <span class="ml-2 text-sm">Potentiale</span>
            </label>
            <label class="flex items-center mb-3">
              <t-toggle
                name="options"
                :checked="showPaths"
                @change="setShowPaths"
              />
              <span class="ml-2 text-sm">Pfade</span>
            </label>
            <label class="flex items-center mb-3">
              <t-toggle
                name="options"
                :checked="showVectors"
                @change="setShowVectors"
              />
              <span class="ml-2 text-sm">Vektoren</span>
            </label>
            <label class="flex flex-items-center mt-3">
              <p class="ml-2 text-sm">Zeitverlauf (0...{{ maxTime }})</p>
            </label>
            <label class="flex items-center">
              <input
                type="range"
                min="0"
                :max="maxTime"
                step="1"
                v-model="momentInTime"
              />
              <span class="ml-2 text-sm">{{ momentInTime }}</span>
            </label>
          </div>
        </div>
        <div class="flex flex-col my-3" v-show="!mode3d">
          <div class="flex items-center">
            <div
              class="w-5 h-5 opacity-60"
              style="background-color: black"
            ></div>
            <span class="text-sm ml-2">Rohr</span>
          </div>
          <div class="flex items-center mt-2">
            <div
              class="w-5 h-5 opacity-60"
              style="background-color: blue"
            ></div>
            <span class="text-sm ml-2">Schraube</span>
          </div>
          <div class="flex items-center mt-2">
            <div class="w-5 h-5" style="background-color: yellow"></div>
            <span class="text-sm ml-2">Spalt</span>
          </div>
        </div>
        <div class="flex flex-row mt-3" v-if="showPotentials && !mode3d">
          <div class="flex items-center">
            <div
              class="w-5 h-5 opacity-50"
              style="background-color: #ff0100"
            ></div>
            <span class="mx-3 text-sm"
              >{{ minPotential.toFixed(2) }} ...
              {{ maxPotential.toFixed(2) }}</span
            >
            <div
              class="w-5 h-5 opacity-50"
              style="background-color: #13ff00"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var canvas;

const LINEWIDTH = 1;
const OUTERTUBE = 40;
const INNERTUBE = 20;
const FACTOR = 100;

import View3d from "@/components/View3d.vue";
export default {
  name: "SvgView",
  components: {
    View3d,
  },
  data() {
    return {
      potentials: [],
      circles: [],
      paths: [],
      vectors: [],
      momentInTime: 0,
    };
  },
  mounted: function() {
    this.$nextTick(function() {
      this.drawCoordinates();
      this.drawEverything();
    });
  },
  methods: {
    drawCoordinates() {
      canvas = this.$svg("svg").size(8 * FACTOR, 8 * FACTOR);
      canvas.panZoom({ zoomMin: 0.1, zoomMax: 5 });
      canvas
        .zoom(0.1, { x: 0, y: 0 })
        .animate(1)
        .zoom(this.zoomLevel, {
          x: this.zoomCoordinates,
          y: this.zoomCoordinates,
        })
        .animate(1);
      for (var i = 0; i <= FACTOR; i++) {
        canvas
          .line(i * FACTOR, 0, i * FACTOR, 100 * FACTOR)
          .stroke({ width: 1 });
        canvas
          .line(0, i * FACTOR, 100 * FACTOR, i * FACTOR)
          .stroke({ width: 1 });
      }
      for (i = 0; i <= 10 * FACTOR; i++) {
        canvas
          .line(i * 10, 0, i * 10, 100 * FACTOR)
          .stroke({ width: 1, color: "#000", opacity: 0.2 });
        canvas
          .line(0, i * 10, 100 * FACTOR, i * 10)
          .stroke({ width: 0.5, color: "#000", opacity: 0.2 });
      }

      this.drawCircles();
    },
    drawEverything() {
      this.momentInTime = this.maxTime;
      this.drawPotentials();
      this.drawCircles();
      this.drawPaths();
    },
    drawCircles() {
      this.circles.forEach((circle) => {
        circle.remove();
      });
      this.vectors.forEach((vector) => {
        vector.remove();
      });
      if (this.outerCircle) this.outerCircle.remove();

      if (this.inputOuterCircle) {
        this.circles.push(
          canvas
            .circle(this.inputOuterCircle.r * 10 * 2 + OUTERTUBE)
            .move(
              this.inputOuterCircle.x * 10 +
                5000 -
                this.inputOuterCircle.r * 10 -
                OUTERTUBE / 2,
              this.inputOuterCircle.y * 10 +
                5000 -
                this.inputOuterCircle.r * 10 -
                OUTERTUBE / 2
            )
            .fill({ color: "#fff", opacity: 0 })
            .stroke({ color: "black", opacity: 0.6, width: OUTERTUBE })
        );

        if (this.dimensioning.flange) {
          this.circles.push(
            canvas
              .circle(this.dimensioning.flange * 10 * 2 + OUTERTUBE)
              .move(
                this.inputOuterCircle.x * 10 +
                  5000 -
                  this.dimensioning.flange * 10 -
                  OUTERTUBE / 2,
                this.inputOuterCircle.y * 10 +
                  5000 -
                  this.dimensioning.flange * 10 -
                  OUTERTUBE / 2
              )
              .fill({ color: "#fff", opacity: 0 })
              .stroke({ color: "black", opacity: 0.6, width: OUTERTUBE })
          );
        }

        this.circles.push(
          canvas
            .circle(this.inputOuterCircle.r * 10 * 2 - LINEWIDTH * 2)
            .move(
              this.inputOuterCircle.x * 10 +
                5000 -
                this.inputOuterCircle.r * 10 +
                LINEWIDTH,
              this.inputOuterCircle.y * 10 +
                5000 -
                this.inputOuterCircle.r * 10 +
                LINEWIDTH
            )
            .fill({ color: "#fff", opacity: 0 })
            .stroke({ color: "yellow", width: LINEWIDTH })
        );
      }

      if (this.inputCirclesTime && this.inputCirclesTime.length) {
        var s = 0;
        var r = 0;
        this.inputCirclesTime.forEach((circle) => {
          var label = "s";
          if (circle.position.f === 1) {
            s++;
            label = "s" + s;
          } else {
            r++;
            label = "r" + r;
          }
          this.drawCircle(circle.position, label);
          if (this.showVectors) {
            this.drawVector(circle);
          }
        });
      }
    },
    drawCircle(circle, label) {
      // Tube
      if (circle.f === -1) {
        // TUBE
        this.circles.push(
          canvas
            .circle(circle.r * 10 * 2 - INNERTUBE)
            .move(
              circle.x * 10 + 5000 - circle.r * 10 + INNERTUBE / 2,
              -circle.y * 10 + 5000 - circle.r * 10 + INNERTUBE / 2
            )
            .fill({
              color: "#ff3300",
              opacity: 0.2,
            })
            .stroke({
              color: "black",
              opacity: 0.6,
              width: INNERTUBE,
            })
        );

        // TUBE TO ELASTOMER GAP
        this.circles.push(
          canvas
            .circle(circle.r * 10 * 2 + LINEWIDTH * 2)
            .move(
              circle.x * 10 + 5000 - circle.r * 10 - LINEWIDTH,
              -circle.y * 10 + 5000 - circle.r * 10 - LINEWIDTH
            )
            .fill({
              color: "transparent",
              opacity: 1,
            })
            .stroke({
              color: "yellow",
              width: this.config.tubeElastomerGap,
            })
        );
      } else {
        /// SCREW
        this.circles.push(
          canvas
            .circle(circle.r * 10 * 2 - 1)
            .move(
              circle.x * 10 + 5000 - circle.r * 10 + 1,
              -circle.y * 10 + 5000 - circle.r * 10 + 1
            )
            .fill({
              color: "#0066ff",
              opacity: 0.2,
            })
            .stroke({
              color: "blue",
              opacity: 0.6,
              width: 1,
            })
        );
        var radiusScheibe = this.config.screws[circle.r * 2].Unterlegscheibe
          .Aussendurchmesser;
        this.circles.push(
          canvas
            .circle(radiusScheibe * 10 - 1)
            .move(
              circle.x * 10 + 5000 - (radiusScheibe / 2) * 10 + 1,
              -circle.y * 10 + 5000 - (radiusScheibe / 2) * 10 + 1
            )
            .fill({
              color: "#0066ff",
              opacity: 0.2,
            })
            .stroke({
              color: "blue",
              opacity: 0.6,
              width: 1,
            })
        );
      }

      var text = canvas
        .text(label)
        .leading(5)
        .move(circle.x * 10 + 5000, -circle.y * 10 + 4850)
        .fill({ color: "#00", opacity: 1 });
      text.font({
        family: "Helvetica",
        size: 50,
      });
      this.circles.push(text);
    },
    drawVector(circle) {
      this.vectors.push(
        canvas
          .line(
            circle.position.x * 10 + 5000,
            -circle.position.y * 10 + 5000,
            circle.position.x * 10 + 5000 + circle.vector.x * 10,
            -circle.position.y * 10 + 5000 - circle.vector.y * 10
          )
          .stroke({ width: 10, color: "blue", linecap: "round" })
      );
      this.vectors.push(
        canvas
          .line(
            circle.position.x * 10 + 5000,
            -circle.position.y * 10 + 5000,
            circle.position.x * 10 + 5000 + circle.vector.x * 1000,
            -circle.position.y * 10 + 5000 - circle.vector.y * 1000
          )
          .stroke({ width: 1, color: "blue", linecap: "round" })
      );
    },
    drawPaths() {
      this.paths.forEach((path) => {
        path.remove();
      });
      this.inputCircles.forEach((circle) => {
        if (circle.ticks && this.showPaths) {
          this.drawPath(circle);
        }
      });
    },
    drawPath(circle) {
      var pathString = "";
      for (var i = 0; i < circle.ticks.length; i++) {
        if (i === 0) {
          pathString += "M";
        } else {
          pathString += "L";
        }
        pathString +=
          circle.ticks[i].position.x * 10 +
          5000 +
          " " +
          (-(circle.ticks[i].position.y * 10) + 5000) +
          " ";
      }
      this.paths.push(
        canvas
          .path(pathString)
          .fill("none")
          .stroke({
            color: circle.f === 1 ? "blue" : "red",
            width: 3,
            linecap: "round",
            linejoin: "round",
          })
      );
    },
    drawPotentials() {
      this.potentials.forEach((potential) => {
        potential.remove();
      });
      if (this.showPotentials) {
        this.inputPotentials.forEach((inputPotential) => {
          this.potentials.push(
            canvas
              .rect(10, 10)
              .move(inputPotential.x * 10 + 4995, -inputPotential.y * 10 + 4995)
              .fill({
                color: this.getPotentialColor(inputPotential.f),
                opacity: 0.5,
              })
          );
        });
      }
    },
    getPotentialColor(perc) {
      var min = this.minPotential;
      var max = this.maxPotential;
      var base = max - min;

      if (base == 0) {
        perc = 100;
      } else {
        perc = ((perc - min) / base) * 100;
      }
      var r,
        g,
        b = 0;
      if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
      } else {
        g = 255;
        r = Math.round(510 - 5.1 * perc);
      }
      var h = r * 0x10000 + g * 0x100 + b * 0x1;
      return "#" + ("000000" + h.toString(16)).slice(-6);
    },
    setMode3d(value) {
      this.$store.dispatch("setMode3d", value);
    },
    setShowPotentials(value) {
      this.$store.dispatch("setShowPotentials", value);
    },
    setShowVectors(value) {
      this.$store.dispatch("setShowVectors", value);
    },
    setShowPaths(value) {
      this.$store.dispatch("setShowPaths", value);
    },
  },
  computed: {
    inputCircles() {
      return this.$store.state.circles;
    },
    inputCirclesTime() {
      var circlesTime = [];
      if (this.inputCircles && this.inputCircles.length)
        this.inputCircles.forEach((circle) => {
          if (circle.ticks && circle.ticks.length) {
            circlesTime.push(circle.ticks[this.momentInTime]);
          }
        });

      return circlesTime.length ? circlesTime : this.inputCircles;
    },
    inputOuterCircle() {
      return this.$store.state.outerCircle;
    },
    inputPotentials() {
      return this.$store.state.potentials;
    },
    showPotentials() {
      return this.$store.state.showPotentials;
    },
    showVectors() {
      return this.$store.state.showVectors;
    },
    mode3d() {
      return this.$store.state.mode3d;
    },
    showPaths() {
      return this.$store.state.showPaths;
    },
    minPotential: function() {
      var minPotential = 999999;
      this.inputPotentials.forEach((potential) => {
        if (potential.f < minPotential) minPotential = potential.f;
      });
      return minPotential;
    },
    maxPotential: function() {
      var minPotential = -999999;
      this.inputPotentials.forEach((potential) => {
        if (potential.f > minPotential) minPotential = potential.f;
      });
      return minPotential;
    },
    config() {
      return this.$store.state.config;
    },
    maxTime() {
      if (
        this.inputCircles &&
        this.inputCircles.length &&
        this.inputCircles[0] &&
        this.inputCircles[0].ticks
      )
        return this.inputCircles[0].ticks.length - 1;
      return 0;
    },
    dimensioning() {
      return this.$store.state.dimensioning;
    },
    zoomCoordinates() {
      return (
        5400 +
        (this.dimensioning.flange
          ? this.dimensioning.flange * 2
          : this.inputOuterCircle.r * 2)
      );
    },
    zoomLevel() {
      return 1500 / this.zoomCoordinates;
    },
  },
  watch: {
    inputCircles: function() {
      this.drawEverything();
    },
    inputPotentials: function() {
      this.drawEverything();
    },
    mode3d: function() {
      this.drawEverything();
    },
    showPotentials: function() {
      this.drawEverything();
    },
    showPaths: function() {
      this.drawEverything();
    },
    showVectors: function() {
      this.drawEverything();
    },
    momentInTime() {
      this.drawCircles();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
