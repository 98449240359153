<template>
  <div>
    <t-textarea
      value="Hello world"
      name="coordinates"
      id="coordinates"
      v-model="string"
      v-on:input="$emit('change', string)"
    />
  </div>
</template>

<script>
export default {
  name: "TextAreaDebugger",
  data() {
    return {
      string:
        '{"circles": [{"x": 0, "y": 0, "r": 3}], "outerCircle": {"x": 0, "y": 0, "r": 6}}',
    };
  },
};
</script>
