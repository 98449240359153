<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-white">
    <div class="w-full px-4 sm:px-6">
      <div
        class="flex justify-between items-center py-6 md:justify-start md:space-x-10"
      >
        <div class="flex justify-center lg:w-0 lg:flex-1">
          <a href="#">
            <span class="sr-only">Workflow</span>
            <img
              class="h-16 w-auto sm:h-24"
              style="max-height:74px"
              src="@/assets/logo.png"
              alt=""
            />
          </a>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button
            type="button"
            class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            @click="menu = !menu"
          >
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: menu -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <!--
        <nav class="hidden md:flex space-x-10">
          <a
            href="#"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Konfigurieren
          </a>
        </nav>
        -->
      </div>
    </div>

    <div
        class="flex flex-wrap py-3 px-5 mb-0 leading-6 text-left text-gray-700 bg-gray-100 rounded-none"
        style="list-style: none; box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;"
    >
      <div
          class="px-5 mx-auto w-full text-left"
          style="max-width: 1240px; list-style: outside none none;"
      >
        <nav
            aria-label="breadcrumb"
            class="block text-gray-700"
            style="list-style: outside none none;"
        >
          <ol
              class="flex flex-wrap py-3 px-0 my-0 bg-transparent rounded-none"
              itemscope=""
              itemtype="https://schema.org/BreadcrumbList"
              style="list-style: none;"
          >
            <li
                style="list-style: outside none none;"
            >
              Konfigurator
            </li>
          </ol>
        </nav>
      </div>
    </div>


    <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  -->
    <div
      class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
      v-if="menu"
    >
      <div
        class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"
      >
        <div class="pt-5 pb-6 px-5">
          <div class="flex items-center justify-between">
            <div>
              <img
                class="h-8 w-auto"
                src="https://www.kroener-dichtungstechnik.de/wp-content/uploads/2017/06/logo.png"
                alt="Workflow"
              />
            </div>
            <div class="-mr-2">
              <button
                type="button"
                class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                @click="menu = !menu"
              >
                <span class="sr-only">Close menu</span>
                <!-- Heroicon name: x -->
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="py-6 px-5 space-y-6">
          <div class="grid grid-cols-2 gap-y-4 gap-x-8">
            <a
              href="#"
              class="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              Konfigurieren
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//https://tailwindui.com/components/marketing/elements/headers
export default {
  name: "HeaderNavigation",
  data() {
    return {
      menu: false,
    };
  },
};
</script>
