import axios from "axios";

const client = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  json: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  async execute({ method, resource, data }) {
    const defaultHeaders = {
      //Authorization: `Bearer ${accessToken}`,
    };

    return client({
      method,
      url: resource,
      data,
      headers: Object.assign({}, defaultHeaders),
    })
      .then((request) => {
        return request.data;
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          return {
            error: error.response.data.message,
          };
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          return {
            error: error.request,
          };
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          return {
            error: error.message,
          };
        }
        //return false;
      });
  },
  requestCreator(method, resource) {
    return { method, resource };
  },
};
